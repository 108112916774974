import Backend from '../inc/backend';
import Bus from '../inc/bus';
import {updateProgress} from '../inc/courseUtils';
import Store from '../inc/store';

export default {
	methods: {
		sendAnswer(answer) {
			let promise = null;

			if(Store.session.timeEnded) {
				promise = Promise.reject(new Error('This course has been completed, no further updates allowed.'));
			}

			else if(Store.courseGroupProgress.timeGraded) {
				promise = Promise.reject(new Error('This unit has been graded, no further updates allowed.'));
			}

			else {
				promise = Backend.post(`sessions/${Store.session.id}/answers`, {
					block: this.block.id,
					group: Store.courseGroup.id,
					answer
				})
				.then(res => this.onAnswerSaved(res));
			}

			return promise.catch(err => this.onAnswerError(err));
		},
		sendAnswerOptions(options, optionGroup) {
			const answer = {
				optionGroup,
				options: []
			};

			if(Array.isArray(options)) {
				answer.options = options;
			}
			else {
				answer.options.push(options);
			}

			return this.sendAnswer([answer]);
		},
		onAnswerSaved() {
			return updateProgress(Store.courseGroup.id);
		},
		onAnswerError(err) {
			Bus.emit('error', err.message);
		},
		getAnswer(groupId) {
			const groupAnswers = Store.groupAnswers.filter(answer => answer.blockId === this.block.id);

			for(const groupAnswer of groupAnswers) {
				if(typeof groupAnswer.answers === 'string' || this.block.type === 'sort') {
					return groupAnswer.answers;
				}
				else if(Array.isArray(groupAnswer.answers)) {
					for(const answer of groupAnswer.answers) {
						if(answer.optionGroup === groupId) {
							return answer.value;
						}
					}
				}
			}

			return '';
		},
		hasAnswerOption(optionId) {
			const groupAnswers = Store.groupAnswers.filter(answer => answer.blockId === this.block.id);

			for(const groupAnswer of groupAnswers) {
				if(Array.isArray(groupAnswer.answers)) {
					for(const answer of groupAnswer.answers) {
						if(answer.options && answer.options.some(option => option.id === optionId)) {
							return true;
						}
					}
				}
			}

			return false;
		}
	}
};