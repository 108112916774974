<template>
	<div class="block-inner">
		<header v-html="question"></header>
		<textarea v-model="text" rows="6" @blur="sendAnswer(text)"></textarea>
	</div>
</template>

<script>
	import Store from '../../inc/store';
	import {jsonToHtml} from '../../inc/text';
	import answerMixin from '../../mixins/answerMixin';

	export default {
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				text: ''
			};
		},
		computed: {
			question() {
				return jsonToHtml(this.block.content);
			},
			answers() {
				return Store.groupAnswers;
			}
		},
		watch: {
			answers() {
				this.text = this.getAnswer();
			}
		},
		created() {
			this.text = this.getAnswer();
		}
	};
</script>

<style lang="scss">
	.freetext.block {
		textarea {
			max-width: 600px;
		}
	}
</style>